import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
height: 1000px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #010606;

@media screen and (max-width: 768px){
  height: 1100px;
}

@media screen and (max-width: 480px){
  height: 1300px;
}
`

export const FormWrap = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;

@media screen and (max-width: 400px){
  height: 80%;
}
`

export const Icon = styled(Link)`
margin-left: 32px;
margin-top: 32px;
text-decoration: none;
color: #fff;
font-weight: 700;
font-size: 32px;

@media screen and (max-width: 480px){
  margin-left: 16px;
  margin-top: 8px;
}
`

export const FormContent = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;

@media screen and (max-width: 480px){
  padding: 10px;
}
`

export const Form = styled.form`
background: #fff;
max-width: 1400px;
height: auto;
width: 1400px;
z-index: 1;
display: grid;
margin: 0 auto;
padding: 80px 32px;
border-radius: 4px;
box-shadow: 0 1px 3px rgba(0,0,0,0.9);

@media screen and (max-width: 400px){
  padding: 32px 32px;
}
`

export const FormH1 = styled.h1`
margin-bottom: 40px;
color: #010101;
font-size: 20px;
font-weight: 400;
text-align: center;
`

export const FormLabel = styled.label`
margin-bottom: 8px;
font-size: 14px;
color: #010101;
`

export const FormInput = styled.input`
padding: 10px 10px;
margin-bottom: 32px;
border: solid;
border-style: solid;
color: black;
border-radius: 4px;
`
export const FormMessage = styled.input`
padding: 10px 10px;
margin-bottom: 32px;
border: solid;
border-style: solid;
color: black;
border-radius: 4px;
`

export const FormButton = styled.button`
background: #01bf71;
padding: 16px 0;
border: none;
border-radius: 4px;
color: #fff;
font-size: 20px;
cursor: pointer;
`

export const Text = styled.span`
text-align: center;
margin-top: 24px;
color: #010101;
font-size: 14px;
`