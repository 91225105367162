import React from 'react';
import SignIn from '../Signin/index';

const SigninPage = () => {
  return(
    <>
    <SignIn />
    </>
  );
};

export default SigninPage;