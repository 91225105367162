export const MenuItemsteam = [
  {
    title: 'Incline Education',
    path: '/team',
    cName: 'dropdown-link'
  },
    {
    title: 'Advisors',
    path: '/advisor',
    cName: 'dropdown-link'
  },
    {
    title: 'Join Us',
    path: '/join-us',
    cName: 'dropdown-link'
  }
]