import React from 'react';
import SignUp from '../Signup/index';

const SignupPage = () => {
  return(
    <>
    <SignUp />
    </>
  );
};

export default SignupPage;