
export const MenuItemsservice = [
  {
    title: 'Accelerator',
    path: '/accelerator',
    cName: 'dropdown-link'
  },
    {
    title: 'Panels',
    path: '/panels',
    cName: 'dropdown-link'
  },
    {
    title: 'Mentorship',
    path: '/mentorship',
    cName: 'dropdown-link'
  },
    {
    title: 'Essentials',
    path: '/essentials',
    cName: 'dropdown-link'
  }
]